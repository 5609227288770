
  @font-face {
    font-family: 'Regata';
    src: url('../fonts/Regata.woff2') format('woff2'),
        url('../fonts/Regata.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



.thumb .img-circle {
    width:100px;
    height:100px;
}

.menuzord-brand {
    margin: 5px 5px 0 0;
    float: left;
    color: #666;
    text-decoration: none;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3;
    cursor: pointer;
}
.menuzord-brand img {
    max-height: 80px;
}